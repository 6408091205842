<template>
  <div>
    <ServicesHeader />
    <span class="hidden-sm-and-down"><ServicesGrid /></span>
    <span class="hidden-md-and-up"><ServicesStairs /></span>
    <Areas />
    <Allies />
    <Customers />
    <ContactForm />
    <Map />
  </div>
</template>

<script>
import ServicesHeader from "@/components/services/ServicesHeader.vue";
import ServicesGrid from "@/components/services/ServicesGrid.vue";
import ServicesStairs from "@/components/shared/ServicesStairs.vue";
import Areas from "@/components/services/Areas.vue";
import Allies from "@/components/shared/Allies.vue";
import Customers from "@/components/shared/Customers.vue";
import Map from "@/components/shared/Map.vue";
import ContactForm from "@/components/shared/ContactForm.vue";

export default {
  name: "Services",
  components: {
    ServicesHeader,
    ServicesGrid,
    ServicesStairs,
    Areas,
    Allies,
    Customers,
    Map,
    ContactForm,
  },
};
</script>
