<template>
  <div
    class="header d-flex justify-center justify-md-start align-space-between align-md-center pt-16 pt-md-0 px-10 px-sm-16"
  >
    <div
      class="d-flex flex-column justify-space-between justify-md-start align-center align-md-start text-center text-md-start mt-16 mt-md-0 pt-16 pt-md-0 px-sm-10"
    >
      <h1
        class="text-h3 text-sm-h2 text-md-h1 white--text mt-16 mt-md-0 mb-10"
        :class="
          $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            ? 'font-weight-bold'
            : 'font-weight-semibold'
        "
      >
        <span class="secondary--text">
          {{ $t("header.services.title.first") }}
        </span>
        {{ $t("header.services.title.second") }}
        <br />
        {{ $t("header.services.title.third") }}
      </h1>
      <p
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        class="cursor-pointer link-button d-flex align-center text-body-1 white--text mb-14 mb-md-0"
        @click="handleScrollServices"
      >
        <span>{{ $t("header.services.linkButton") }}</span>
        <v-icon
          size="18"
          class="chevron ml-3"
          :alt="$t('header.services.chevronAlt')"
        >
          $chevronWhite
        </v-icon>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesHeader",
  methods: {
    handleScrollServices() {
      document
        .getElementById("servicios")
        .scrollIntoView({ block: "center", behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  min-height: 658px;
  background: url("../../assets/ILUM/SERVICIO/HERO_IMAGE.png") no-repeat center
    center !important;
  background-size: cover !important;
}
.cursor-pointer {
  cursor: pointer;
}

.link-button:hover .chevron {
  animation: shaking 0.5s infinite;
}

@keyframes shaking {
  0% {
    transform: rotateZ(8deg);
  }
  50% {
    transform: rotateZ(-8deg);
  }
  100% {
    transform: rotateZ(8deg);
  }
}
</style>
