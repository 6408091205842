<template>
  <v-sheet min-height="418" class="d-flex align-center px-16">
    <v-row class="px-10 my-16" no-gutters>
      <v-col
        v-for="(service, i) in services"
        :key="i"
        cols="4"
        :class="i == 0 ? 'pr-8' : i == 1 ? 'px-4' : i == 2 && 'pl-8'"
      >
        <div class="d-flex align-baseline mb-8">
          <v-img
            :max-width="service.iconWidth"
            contain
            :src="service.icon"
            :alt="$t(service.iconAlt)"
            class="mr-4"
          />
          <h2 class="text-h2 font-weight-bold secondary--text">
            {{ $t(service.title) }}
          </h2>
        </div>
        <div class="text-body-1 secondary--text">
          <p>
            {{ $t(service.description.first) }}
          </p>
          <p v-if="service.description.second">
            {{ $t(service.description.second) }}
            <span v-if="service.description.third">
              <br />
              {{ $t(service.description.third) }}
            </span>
          </p>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import BackOfficeIcon from "@/assets/icons/services/BackOfficeIcon.svg";
import FamilyOfficeIcon from "@/assets/icons/services/FamilyOfficeIcon.svg";
import StrategyIcon from "@/assets/icons/services/StrategyIcon.svg";

export default {
  name: "ServicesGrid",
  data() {
    return {
      services: [
        {
          title: "servicesGrid.first.title",
          description: {
            first: "servicesGrid.first.description.first",
            second: "servicesGrid.first.description.second",
            third: "servicesGrid.first.description.third",
          },
          icon: BackOfficeIcon,
          iconWidth: 32,
          iconAlt: "servicesGrid.first.iconAlt",
        },
        {
          title: "servicesGrid.second.title",
          description: {
            first: "servicesGrid.second.description.first",
            second: "servicesGrid.second.description.second",
          },
          icon: FamilyOfficeIcon,
          iconWidth: 32,
          iconAlt: "servicesGrid.second.iconAlt",
        },
        {
          title: "servicesGrid.third.title",
          description: {
            first: "servicesGrid.third.description.first",
          },
          icon: StrategyIcon,
          iconWidth: 14,
          iconAlt: "servicesGrid.third.iconAlt",
        },
      ],
    };
  },
};
</script>
