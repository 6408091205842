<template>
  <div class="secondary py-16 py-md-16 px-10 px-sm-16">
    <div class="py-md-16 px-sm-10">
      <div class="yellow--text mb-5">
        <h2 class="text-subtitle-1 text-md-h2 font-weight-semibold">
          {{ $t("areas.title") }}
        </h2>
      </div>

      <div
        class="white--text text-subtitle-2 text-md-subtitle-1 mb-14"
        :class="
          $vuetify.breakpoint.xs ||
          ($vuetify.breakpoint.sm && 'font-weight-semibold')
        "
      >
        <p>
          {{ $t("areas.description") }}
        </p>
      </div>
      <div class="mb-0 mb-md-2">
        <div
          class="cursor-pointer d-flex flex-row align-center mb-8"
          @click="handleClose(0)"
        >
          <v-sheet
            color="transparent"
            :width="$vuetify.breakpoint.xs ? 35 : 45"
            class="d-flex justify-center mr-6"
          >
            <v-img
              :max-width="$vuetify.breakpoint.xs ? 15 : 21"
              contain
              src="../../assets/icons/StrategicPlanningIcon.svg"
              :alt="$t('areas.items.first.iconAlt')"
            />
          </v-sheet>
          <h3
            class="white--text text-subtitle-2 text-md-h2 font-weight-semibold"
          >
            {{ $t("areas.items.first.title") }}
          </h3>
          <div class="ml-4 d-flex align-center">
            <v-icon
              v-if="!closeList[0].isOpen"
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.plus')"
            >
              fa fa-plus
            </v-icon>
            <v-icon
              v-else
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.minus')"
            >
              fa fa-minus
            </v-icon>
          </div>
        </div>
        <div
          id="strategy"
          class="container-close white--text text-subtitle-2 text-md-body-1"
        >
          <div class="mb-4">
            <p>
              {{ $t("areas.items.first.description") }}
            </p>
          </div>
          <ul>
            <li class="my-md-2">
              {{ $t("areas.items.first.benefits.first") }}
            </li>
            <li class="my-md-2">
              {{ $t("areas.items.first.benefits.second") }}
            </li>
            <li class="my-md-2">
              {{ $t("areas.items.first.benefits.third") }}
            </li>
            <li class="my-md-2">
              {{ $t("areas.items.first.benefits.fourth") }}
            </li>
            <li class="my-md-2">
              {{ $t("areas.items.first.benefits.fifth") }}
            </li>
            <li class="my-md-2">
              {{ $t("areas.items.first.benefits.sixth") }}
            </li>
          </ul>
        </div>
      </div>
      <div class="mb-0 mb-md-2">
        <div
          class="cursor-pointer d-flex flex-row align-center mb-8"
          @click="handleClose(1)"
        >
          <v-sheet
            color="transparent"
            :width="$vuetify.breakpoint.xs ? 35 : 45"
            class="d-flex justify-center mr-6"
          >
            <v-img
              :max-width="$vuetify.breakpoint.xs ? 26 : 35"
              src="../../assets/icons/FinanceIcon.svg"
              :alt="$t('areas.items.second.iconAlt')"
            />
          </v-sheet>
          <h3
            class="white--text text-subtitle-2 text-md-h2 font-weight-semibold"
          >
            {{ $t("areas.items.second.title") }}
          </h3>
          <div class="ml-4 d-flex align-center">
            <v-icon
              v-if="!closeList[1].isOpen"
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.plus')"
            >
              fa fa-plus
            </v-icon>
            <v-icon
              v-else
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.minus')"
            >
              fa fa-minus
            </v-icon>
          </div>
        </div>
        <div
          id="finance"
          class="container-close white--text text-subtitle-2 text-md-body-1"
        >
          <div class="mb-4">
            <p>
              {{ $t("areas.items.second.description") }}
            </p>
          </div>
          <ul class="mb-4">
            <li class="my-md-2">
              {{ $t("areas.items.second.benefits.first") }}
            </li>
            <li class="my-md-2">
              {{ $t("areas.items.second.benefits.second") }}
            </li>
            <li class="my-md-2">
              {{ $t("areas.items.second.benefits.third") }}
            </li>
            <li class="my-md-2">
              {{ $t("areas.items.second.benefits.fourth") }}
            </li>
          </ul>
          <div class="mb-4">
            <p>
              {{ $t("areas.items.second.extraInfo") }}
            </p>
          </div>
        </div>
      </div>
      <div class="mb-0 mb-md-2">
        <div
          class="cursor-pointer d-flex flex-row align-center mb-8"
          @click="handleClose(2)"
        >
          <v-sheet
            color="transparent"
            :width="$vuetify.breakpoint.xs ? 35 : 45"
            class="d-flex justify-center mr-6"
          >
            <v-img
              :max-width="$vuetify.breakpoint.xs ? 26 : 35"
              src="../../assets/icons/OrganizationalCultureIcon.svg"
              :alt="$t('areas.items.third.iconAlt')"
            />
          </v-sheet>
          <h3
            class="white--text text-subtitle-2 text-md-h2 font-weight-semibold"
          >
            {{ $t("areas.items.third.title") }}
          </h3>
          <div class="ml-4 d-flex align-center">
            <v-icon
              v-if="!closeList[2].isOpen"
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.plus')"
            >
              fa fa-plus
            </v-icon>
            <v-icon
              v-else
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.minus')"
            >
              fa fa-minus
            </v-icon>
          </div>
        </div>
        <div
          id="administration"
          class="container-close white--text text-subtitle-2 text-md-body-1"
        >
          <div class="mb-4">
            <p>
              {{ $t("areas.items.third.description") }}
            </p>
          </div>
          <ul>
            <li class="my-md-2">
              {{ $t("areas.items.third.activities.first") }}
            </li>
            <li class="my-md-2">
              {{ $t("areas.items.third.activities.second") }}
            </li>
            <li class="my-md-2">
              {{ $t("areas.items.third.activities.third") }}
            </li>
          </ul>
        </div>
      </div>
      <div class="mb-0 mb-md-2">
        <div
          class="cursor-pointer d-flex flex-row align-center mb-8"
          @click="handleClose(3)"
        >
          <v-sheet
            color="transparent"
            :width="$vuetify.breakpoint.xs ? 35 : 45"
            class="d-flex justify-center mr-6"
          >
            <v-img
              :max-width="$vuetify.breakpoint.xs ? 31 : 42"
              src="../../assets/icons/LegalIcon.svg"
              :alt="$t('areas.items.fourth.iconAlt')"
            />
          </v-sheet>
          <h3
            class="white--text text-subtitle-2 text-md-h2 font-weight-semibold"
          >
            {{ $t("areas.items.fourth.title") }}
          </h3>
          <div class="ml-4 d-flex align-center">
            <v-icon
              v-if="!closeList[3].isOpen"
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.plus')"
            >
              fa fa-plus
            </v-icon>
            <v-icon
              v-else
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.minus')"
            >
              fa fa-minus
            </v-icon>
          </div>
        </div>
        <div
          id="legal"
          class="container-close white--text text-subtitle-2 text-md-body-1"
        >
          <div class="mb-4">
            <p>
              {{ $t("areas.items.fourth.description") }}
            </p>
          </div>
        </div>
      </div>
      <div class="mb-0 mb-md-2">
        <div
          class="cursor-pointer d-flex flex-row align-center mb-8"
          @click="handleClose(4)"
        >
          <v-sheet
            color="transparent"
            :width="$vuetify.breakpoint.xs ? 35 : 45"
            class="d-flex justify-center mr-6"
          >
            <v-img
              :max-width="$vuetify.breakpoint.xs ? 21 : 29"
              src="../../assets/icons/InnovationIcon.svg"
              :alt="$t('areas.items.fifth.iconAlt')"
            />
          </v-sheet>
          <h3
            class="white--text text-subtitle-2 text-md-h2 font-weight-semibold"
          >
            {{ $t("areas.items.fifth.title") }}
          </h3>
          <div class="ml-4 d-flex align-center">
            <v-icon
              v-if="!closeList[4].isOpen"
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.plus')"
            >
              fa fa-plus
            </v-icon>
            <v-icon
              v-else
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.minus')"
            >
              fa fa-minus
            </v-icon>
          </div>
        </div>
        <div
          id="innovation"
          class="container-close white--text text-subtitle-2 text-md-body-1"
        >
          <div class="mb-4">
            <p>
              {{ $t("areas.items.fifth.description") }}
            </p>
          </div>
          <ul class="mb-4">
            <li class="my-md-2">{{ $t("areas.items.fifth.areas.first") }}</li>
            <li class="my-md-2">{{ $t("areas.items.fifth.areas.second") }}</li>
            <li class="my-md-2">{{ $t("areas.items.fifth.areas.third") }}</li>
            <li class="my-md-2">{{ $t("areas.items.fifth.areas.fourth") }}</li>
          </ul>

          <div class="mb-4">
            <p>
              {{ $t("areas.items.fifth.extraInfo.text") }}
              <a
                class="cursor-pointer white--text font-weight-semibold text-decoration-underline"
                href="https://ilum.vc/lansera/"
                target="_blank"
              >
                {{ $t("areas.items.fifth.extraInfo.linkButton") }}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="mb-0 mb-md-2">
        <div
          class="cursor-pointer d-flex flex-row align-center mb-8"
          @click="handleClose(5)"
        >
          <v-sheet
            color="transparent"
            :width="$vuetify.breakpoint.xs ? 35 : 45"
            class="d-flex justify-center mr-6"
          >
            <v-img
              :max-width="$vuetify.breakpoint.xs ? 31 : 41"
              src="../../assets/icons/MarketingIcon.svg"
              :alt="$t('areas.items.sixth.iconAlt')"
            />
          </v-sheet>
          <h3
            class="white--text text-subtitle-2 text-md-h2 font-weight-semibold"
          >
            {{ $t("areas.items.sixth.title") }}
          </h3>
          <div class="ml-4 d-flex align-center">
            <v-icon
              v-if="!closeList[5].isOpen"
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.plus')"
            >
              fa fa-plus
            </v-icon>
            <v-icon
              v-else
              color="yellow"
              :size="$vuetify.breakpoint.xs ? 15 : 23"
              :alt="$t('areas.alts.minus')"
            >
              fa fa-minus
            </v-icon>
          </div>
        </div>
        <div
          id="marketing"
          class="container-close white--text text-subtitle-2 text-md-body-1"
        >
          <div class="mb-4">
            <p>
              {{ $t("areas.items.sixth.description") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Areas",
  data() {
    return {
      closeList: [
        { name: "strategy", isOpen: false },
        { name: "finance", isOpen: false },
        { name: "administration", isOpen: false },
        { name: "legal", isOpen: false },
        { name: "innovation", isOpen: false },
        { name: "marketing", isOpen: false },
      ],
    };
  },
  methods: {
    handleClose(index) {
      if (!this.closeList[index].isOpen) {
        document
          .getElementById(this.closeList[index].name)
          .classList.remove("container-close");
        document
          .getElementById(this.closeList[index].name)
          .classList.add("container-show");
      } else {
        document
          .getElementById(this.closeList[index].name)
          .classList.remove("container-show");
        document
          .getElementById(this.closeList[index].name)
          .classList.add("container-close");
      }

      this.closeList[index].isOpen = !this.closeList[index].isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.blue-back-waves {
  background: url("../../assets/ILUM/SERVICIO/FONDO_AZUL_ONDAS_LARGO.png")
    no-repeat center center !important;
  background-size: cover !important;
}

.container-close {
  transition: all 0.5s;
  overflow: hidden;
  max-height: 0;
  padding-bottom: 0;
}

.container-show {
  transition: all 0.8s;
  overflow: hidden;
  max-height: 550px;
  padding-bottom: 50px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
